<script>
  export default {
    props: ["name", "label", "type", "placeholder", "required", "value"],
    data() {
      return {
        nameOfType: "jpg, jpeg ó png",
        onChangeInput: false,
        currentInput: null,
        imageUrl: null,
        preview: null,
        alertTxt: "",
        requiredTxt: null,
      };
    },
    methods: {
      onChange(event) {
        const input = this.$refs.fileInput;
        const [file] = input.files;

        if (!file) {
          input.parentNode.replaceChild(this.currentInput, input);
          this.currentInput.addEventListener("change", this.onChange.bind(this));

          this.$refs.fileInput = this.currentInput;
          return;
        }

        this.onChangeInput = true;
        this.currentInput = input.cloneNode(true);

        if (file && this.isValidFile(file)) {
          this.updateImagePreview(file);
          this.editGap("16px", "0px");
          this.$emit("change", event);
        }
      },
      isValidFile(file) {
        const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
        const validExtensions = ["jpg", "jpeg", "png"];
        const fileType = file.type;
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (!validImageTypes.includes(fileType) && !validExtensions.includes(fileExtension)) {
          this.resetImagePreview();
          this.showInvalidFileAlert();
          return false;
        }
        return true;
      },
      updateImagePreview(file) {
        if (this.imageUrl) {
          this.$emit("urlImg", this.imageUrl);
        }

        this.imageUrl = URL.createObjectURL(file);
        this.$emit("urlImg", this.imageUrl);
        this.nameOfType = file.name;
        this.alertTxt = "";
        this.$refs.requiredTxt.classList.add("picture__required--before");
        this.$refs.elementName.classList.add("picture__gradient");
        this.$refs.image.classList.add("picture__image--before");
        this.$refs.txtType.style.color = "#fff";
        this.$refs.preview.style.border = "";
      },
      showInvalidFileAlert() {
        this.alertTxt = "El tipo de archivo que intentas subir no es válido.";
        this.$refs.preview.style.border = "solid 2px #bd0909";
        this.$refs.requiredTxt.classList.remove("picture__required--before");
        this.editGap("34px", "30px");
      },
      editGap(gapPx, margin) {
        const photoElement = document.querySelector(".signup-section-photos");
        const uploadvideoELement = document.querySelector(".uploadvideo");
        if (photoElement && uploadvideoELement) {
          photoElement.style.gap = gapPx;
          uploadvideoELement.style.marginTop = margin;
        }
      },
      resetImagePreview() {
        this.$emit("urlImg", this.imageUrl);
        this.onChangeInput = false;
        this.imageUrl = null;
        this.nameOfType = "jpg, jpeg, png ó heic";
        this.$refs?.requiredTxt.classList.remove("picture__required--before");
        this.$refs?.image.classList.remove("picture__image--before");
        this.$refs.txtType.style.color = "";
        this.$refs?.elementName.classList.remove("picture__gradient");
        this.alertTxt = "";
        this.$refs.fileInput.value = "";

        this.$emit("change", this.$refs.fileInput);
      },
    },
  };
</script>

<template>
  <label :class="`signup-section-form-data-input __fileInput ${imageUrl ? '__fill' : '__empty'}`" v-if="name">
    <p role="label" ref="requiredTxt" class="picture__required" :required="required">{{ label }}</p>
    <div class="picture__trashWrapper" v-if="imageUrl" @click.prevent="resetImagePreview"><iconic name="newTrash" /></div>
    <div ref="preview" class="picture" :style="`--image: url('${imageUrl}');`">
      <div ref="image" class="picture__image"><iconic class="picture__iconimage" name="image" v-show="!onChangeInput" /></div>
      <div ref="elementName" class="picture__gradientElement">
        <p ref="txtType" class="picture__txtType">{{ nameOfType }}</p>
        <p v-if="!onChangeInput" class="picture__placeholder">{{ placeholder }}</p>
      </div>
      <input ref="fileInput" :type="type" :name="name" :required="required" @change="onChange" accept=".png, .jpg, .jpeg, .heic" class="picture__input" />
    </div>
    <p v-show="alertTxt" class="picture__tooltip">{{ alertTxt }}</p>
  </label>
</template>

<style lang="scss" scoped>
  .__fileInput {
    --picture-size: 67px;
    @include Flex(column, flex-end, flex-start);
    width: 100%;
    margin: $space-0;
    &.__fill {
      input[type="file" i]::before {
        content: "";
      }
    }
    input[type="file" i] {
      height: auto;
      padding: $space-0;
      border: none;
      background: transparent;
    }
    .picture {
      @include Flex(column, center, center);
      min-height: 150px;
      background-color: $lightgray;
      border-radius: $radius-12;
      width: 100%;
      height: 100%;
      cursor: pointer;
      transition: 0.3s;
      &__image {
        width: 100%;
        border-radius: $mradius;
        background-image: var(--image);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include Flex(inherit, center, center);
        font-size: 150%;
        margin-top: 45px;
        &--before {
          height: 100%;
          margin: $space-0;
        }
      }
      &__required {
        position: absolute;
        top: 10px;
        left: 10px;
        &--before {
          color: #fff;
          text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.8);
        }
      }
      &__iconimage {
        font-size: 40px;
      }
      &__input {
        display: none;
      }
      &__placeholder {
        font-size: 9px;
        color: #4a4a4a;
      }
      &__txt,
      &__txtType {
        font-size: 11px;
        width: fit-content;
        color: #171717;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__txtType {
        max-width: 80%;
        white-space: nowrap;
      }
      &__gradient,
      &__gradientElement {
        @include Flex(column, center, center);
        width: 100%;
        height: 50px;
        gap: $space-2;
        padding-top: $space-4;
      }
      &__gradient {
        @include Flex(column, center, flex-start);
        padding: 3px $space-0 $space-0 10px;
        border-radius: $radius-0 $radius-0 10px 10px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 120%);
        color: #fff;
        height: 45px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &:hover {
        background-color: rgba(79, 79, 79, 0.25);
      }
      &__tooltip {
        position: absolute;
        bottom: -25px;
        font-size: 8px;
        margin-top: $space-2;
        color: $primary_color;
        font-weight: bold;
        max-width: 213px;
      }
      &__trashWrapper {
        position: absolute;
        top: 10px;
        right: 10px;
        @include Flex(row);
        height: 30px;
        width: 30px;
        font-size: 16px;
        border-radius: 8px;
        background: #11111199;
        color: #fff;
        transition: 0.15s ease-in-out;
        box-shadow: 0px 0px 1px 0px #00000059;
        &:hover {
          color: $primary-color;
          background: #ffffff80;
        }
      }
      @media screen and (min-width: 425px) {
        &__image {
          margin-top: $space-20;
          &--before {
            margin: $space-0;
          }
        }
        &__tooltip {
          font-size: 9px;
        }
      }
      @media screen and (min-width: 768px) {
        &__txt,
        &__placeholder {
          font-size: 12px;
        }
      }
      @media (min-width: 980px) and (max-width: 1024px) {
        &__placeholder {
          text-align: center;
        }
        &__image {
          margin-top: 30px;
        }
        &__tooltip {
          font-size: 8px;
        }
      }
    }
  }
</style>
